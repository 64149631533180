<template>
  <div>
    <!-- 套餐标签 -->
        <!-- 搜索栏 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.name" clearable placeholder="搜索标签名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" icon="el-icon-search" @click="refresh" >搜索</el-button>
      </el-form-item>
      <el-form-item class="fr">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('goods:goodsLabel:add')" >添加标签</el-button>
      </el-form-item>
    </el-form>

    <div class="content-wrap">
     <!--列表-->
      <paged-table
        :data="goodsLableList"
        :total="goodsLableTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
      >
        
        <el-table-column
          prop="name"
          label="标签"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="description"
          label="标签描述"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column
          align="right"
          label="操作"
          width="120"
          fixed="right"
        >
          <template slot-scope="scope" >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="handleForm(true, scope.row)"
              v-if="OA.includes('goods:goodsLabel:editor')"
              title="编辑"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              v-if="OA.includes('goods:goodsLabel:del')"
              @click="handleDelete({ id: scope.row.id })"
              title="删除"
            ></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>
  <!-- 添加和编辑界面 -->
    <el-dialog :title="isEditor ? '编辑标签' : '添加标签'" :visible.sync="aVisible" width="800px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form"  size="small">
       
        <el-form-item label="标签名称：" prop="name" style="width: 60%">
          <el-input v-model="form.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="标签描述：" prop="description"   style="width: 60%">
          <el-input v-model="form.description" type="textarea"  :rows="4"  clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

  
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'goodsLabel',
  components: {
    PagedTable
  },
  mixins: [pageMixin,provinces,download],
  data() {
    return {
      BaseUrl,
      queryParams: {
        name:''
      },
      schoolType: [],
      form:{
        name:'',
        description:'',
        id:''
      },
      details:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      pageApi:'getGoodsLableList',
      insertApi:'getGoodsLableAdd',
      editorApi:'getGoodsLableEdit',
      deleteApi:'getGoodsLableDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("goodsLable", ["goodsLableList", "goodsLableTotal"]),
  },
  methods: {
     ...mapActions("goodsLable",[
       "getGoodsLableList",
       "getGoodsLableAll",
       "getGoodsLableAdd",
       "getGoodsLableDelete",
       "getGoodsLableEdit"
       ]),
  
   
  },
  mounted(){
     this.refresh()
  }
};
</script>